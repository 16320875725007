import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Card, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { GoogleLogin } from "react-google-login";
import { getLogin } from "../hooks/login";
// import { gapi } from "gapi-script";

const useStyles = makeStyles((theme) => ({
  container: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    height: `100vh`,
    backgroundColor: "#2E8ECD",
    padding: 0,
    margin: 0,
  },
  loginForm: {
    opacity: 0.9,
    borderRadius: 15,
    padding: 25,
    background: "#fff",
    width: "370px",
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    flexDirection: "column",
  },
  loginLogo: {
    marginBottom: 10,
  },
  large: {
    maxHeight: 250,
    maxWidth: 350,
  },
  buttonLogin: {
    marginTop: 50,
    marginBottom: 0,
    minHeight: 40,
    borderRadius: 50,
    background: "#F4BD21",
    color: "white",
    "&:hover": {
      backgroundColor: "#DA9C17",
    },
  },
}));

function LoginScreen() {
  const classes = useStyles();

  const [isLoading, setIsLogin] = useState(false);

  const responseGoogle = async (response) => {
    setIsLogin(true);
    const responseLogin = await getLogin(response.profileObj.email);
    if (responseLogin.data) {
      sessionStorage.setItem("googleUser", JSON.stringify(response));
      setTimeout(() => {
        window.location.href = "/";
        setIsLogin(false);
      }, 1000);
    } else {
      logout(false);
      setIsLogin(false);
      alert("Usuário não permitido!");
    }
  };

  const logout = () => {
    const auth2 = window?.gapi?.auth2?.getAuthInstance();

    if (auth2 != null) {
      auth2
        .signOut()
        .then(auth2.disconnect().then(console.log("LOGOUT SUCCESSFUL")));
    }
  };

  const responseFailure = () => {
    alert("Falha ao logar com o google. Tente novamente!");
  };

  useEffect(() => {
    logout();
  }, []);

  return (
    <>
      <Box className={classes.container}>
        <Card className={classes.loginForm}>
          <img
            src={"https://dev.telescopelms.com/lms/images/logo"}
            alt="logo"
            className={classes.large}
          />
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <h3 style={{ color: "gray" }}>
              Para acessar o ambiente você precisa de uma conta Google. Clique
              no botão abaixo para fazer login:
            </h3>
          </div>
          <GoogleLogin
            clientId="836236441113-kecedgtqctucig1529n6brv3odvbdh18.apps.googleusercontent.com"
            render={(renderProps) => (
              <Button
                type="submit"
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                fullWidth
                className={classes.buttonLogin}
                variant="contained"
              >
                {isLoading ? (
                  <CircularProgress size={22} color="primary" />
                ) : (
                  "Login com o Google"
                )}
              </Button>
            )}
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseFailure}
            isSignedIn={false}
            cookiePolicy={"single_host_origin"}
          />
          <div style={{ marginTop: 15 }}>
            <a href="/" style={{ textDecoration: "none" }}>
              <span style={{ color: "#79AAE5", fontWeight: "bolder" }}>
                Termos de serviço
              </span>
            </a>
          </div>
        </Card>
      </Box>
    </>
  );
}

export default LoginScreen;

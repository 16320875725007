/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import moment from "moment";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F5F6FA",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
    margin: 0,
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    color: "#fff",
    zIndex: 8888,
    position: "absolute",
    width: "90%",
    height: "90%",
    top: `50%`,
    left: "50%",
    transform: `translate(-50%, -50%)`,
    "@media (max-width: 600px)": {
      width: "90%",
      top: 25,
      left: "50%",
      transform: `translate(-50%)`,
    },
    backgroundColor: "#fff",
    border: "2px solid #cecece",
    boxShadow: theme.shadows[5],
    padding: 5,
  },
  table: {
    width: "100%",
    maxHeight: "90%",
    overflowY: "auto",
  },
}));

const ContentTableModal = ({
  chromeBooksList,
  handlePaginate,
  rankingSchools,
}) => {
  const classes = useStyles();

  const [page, setPage] = React.useState(1);
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const [unit, setUnit] = React.useState("all");

  const handleChangeUnit = (event) => {
    setPage(1);
    setUnit(event.target.value);
  };

  const [stateFilter, setStateFilter] = React.useState("all");

  const handleChangeFilters = (event) => {
    setPage(1);
    setStateFilter(event.target.value);
  };

  const [statePeriod, setStatePeriod] = React.useState("all");

  const handleChangePeriod = (event) => {
    setPage(1);
    setStatePeriod(event.target.value);
  };

  const formatSchoolName = (name) => {
    if (name === "/") return "Não relacionado";
    return name.replace("/", "");
  };

  useEffect(() => {
    handlePaginate({
      page: page,
      perPage: 10,
      unit,
      group: stateFilter,
      period: statePeriod,
    });
  }, [page, unit, stateFilter, statePeriod]);

  return (
    <Paper className={classes.paper}>
      <CustomizedTables chromeBooksList={chromeBooksList?.chromeosdevices} />
      <div className={classes.root}>
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Unidade</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={unit}
              onChange={handleChangeUnit}
            >
              <MenuItem value={"all"}>Todas as unidades</MenuItem>
              {rankingSchools &&
                rankingSchools?.data.map((item) => (
                  <MenuItem value={item.name}>
                    {formatSchoolName(item.name)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Filtrar</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={stateFilter}
              onChange={handleChangeFilters}
            >
              <MenuItem value={"all"}>Todos</MenuItem>
              <MenuItem value={"ACTIVE"}>Ativos</MenuItem>
              <MenuItem value={"DEPROVISIONED"}>Inativos</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Período</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={statePeriod}
              onChange={handleChangePeriod}
            >
              <MenuItem value={"all"}>Todos</MenuItem>
              <MenuItem value={"30"}>Últimos 30 dias</MenuItem>
              <MenuItem value={"60"}>Últimos 60 dias</MenuItem>
              <MenuItem value={"90"}>Últimos 90 dias</MenuItem>
              <MenuItem value={"-30"}>Sem login em 30 dias</MenuItem>
              <MenuItem value={"-7"}>Sem login em 7 dias</MenuItem>
              <MenuItem value={"-3"}>Sem login em 3 dias</MenuItem>
            </Select>
          </FormControl>
        </div>
        <Pagination
          count={Math.ceil(chromeBooksList?.total / 10)}
          page={page}
          onChange={handleChangePage}
        />
      </div>
    </Paper>
  );
};

function CustomizedTables({ state, chromeBooksList = null }) {
  const classes = useStyles();

  const showRecentUsers = (recentUsers) => {
    return recentUsers.filter(recent => recent?.email).map((recent) => recent.email).join(", ");
  }

  const countRecentUsers = (recentUsers) => {
    const count = recentUsers.filter(recent => recent?.email)?.length;

    return count > 0 ? `(${count})` : "...";
  }

  const formatSchoolName = (name) => {
    if (name === "/") return "Não relacionado";
    return name.replace("/", "");
  };

  const formatDateTime = (date) => {
    return moment(date).format("DD/MM/YYYY HH:mm:ss");
  };

  const formatDate = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };

  function getSum(total, num) {
    return total + Math.round(num?.activeTime);
  }

  const formatSessionTime = (activeTimeRanges) => {
    if (!activeTimeRanges) {
      return "...";
    } else {
      let counterTime = activeTimeRanges.reduce(getSum, 0);
      counterTime = moment({}).seconds(counterTime).format("H:mm:ss");
      return `${counterTime}`;
    }
  };

  return (
    <TableContainer component={Paper} style={{ maxHeight: "90%" }}>
      <Table
        className={classes.table}
        size="small"
        stickyHeader
        aria-label="sticky table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell>Escola</StyledTableCell>
            <StyledTableCell align="right">Modelo</StyledTableCell>
            <StyledTableCell align="right">Nº de série</StyledTableCell>
            <StyledTableCell align="right">Chrome OS v</StyledTableCell>
            <StyledTableCell align="right">Última inscrição</StyledTableCell>
            <StyledTableCell align="right">Último login</StyledTableCell>
            <StyledTableCell align="right">Últimos Usuários</StyledTableCell>
            <StyledTableCell align="right">Tempo de Sessão</StyledTableCell>
            <StyledTableCell align="right">
              Data máxima para suporte
            </StyledTableCell>
            <StyledTableCell align="right">
              Usuário do dispositivo
            </StyledTableCell>
            <StyledTableCell align="right">
              Local do dispositivo
            </StyledTableCell>
            <StyledTableCell align="right">
              Identificador de recursos
            </StyledTableCell>
            <StyledTableCell align="right">Observações</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {chromeBooksList &&
            chromeBooksList.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {formatSchoolName(row.orgUnitPath)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.model || ""}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.serialNumber || ""}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.osVersion || ""}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatDateTime(row.lastEnrollmentTime)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatDateTime(row.lastSync)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row?.recentUsers?.length > 0 ? `${countRecentUsers(row?.recentUsers) }`: "..."} <br/>
                  {row?.recentUsers?.length > 0 && showRecentUsers(row?.recentUsers)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatSessionTime(row?.activeTimeRanges)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatDate(row.autoUpdateThrough) || ""}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.annotatedUser || ""}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.annotatedLocation || ""}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.annotatedAssetId || ""}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.notes || ""}
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default ContentTableModal;

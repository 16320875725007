import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import SchoolInfo from "./schoolInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
    margin: "10px 0",
  },
  paper: {
    color: "#fff",
    position: "absolute",
    width: "80%",
    maxHeight: "90%",
    top: `50%`,
    left: "50%",
    transform: `translate(-50%, -50%)`,
    "@media (max-width: 600px)": {
      width: "90%",
      top: `30%`,
      left: "50%",
      transform: `translate(-50%)`,
    },
    backgroundColor: "#fff",
    border: "2px solid #cecece",
    boxShadow: theme.shadows[5],
    padding: 5,
  },
  table: {
    width: "100%",
  },
}));

const ContentHankingModal = ({
  rankingListPaginate,
  handleRankingPaginate,
  perPage = 10,
}) => {
  const classes = useStyles();

  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    handleRankingPaginate(value);
  };
  return (
    <Paper className={classes.paper}>
      <h2 style={{ color: "black", marginLeft: 15 }}>
        Dispositivos por unidade
      </h2>
      <SchoolInfo rankingSchools={rankingListPaginate} />
      <div className={classes.root}>
        <Pagination
          count={Math.ceil(rankingListPaginate?.totalSchools / perPage)}
          page={page}
          onChange={handleChange}
        />
      </div>
    </Paper>
  );
};

export default ContentHankingModal;

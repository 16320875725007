import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(0),
    color: theme.palette.text.secondary,
    marginTop: 30,
    minHeight: 100,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardItem: {
    paddingRight: 10,
    marginTop: 15,
    color: "#536489",
    // width: 366.73,
    height: 137,
    background: "#FFFFFF",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    margin: theme.spacing(1),
    marginRight: 0,
    background: "#233674",
    borderRadius: 4,
    minWidth: 138,
    height: 40,
  },
  textButton: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
    color: "#fff",
    textTransform: "capitalize",
  },
  cardValue: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 45,
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#233674",
  },
  cardTitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 16,
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#484B4D",
    marginTop: 10,
  },
  chomebooks: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 32,
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#233674",
  },
}));

const CardItem = ({ name, value, isLast = false }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.cardItem}
      style={{ borderRightWidth: `${isLast ? 0 : 0.5}` }}
    >
      <span className={classes.cardValue}>{value}</span>
      <span className={classes.cardTitle}>{name}</span>
    </div>
  );
};

const CardInfo = ({
  totalProvisioned,
  totalActive,
  timeUseTotal,
  processAll,
  getInitialData,
  setLoading,
  setOpenModalOrgunit = () => {},
}) => {
  const classes = useStyles();

  const handleSync = async () => {
    setLoading(true);
    await processAll();
    setTimeout(async () => {
      await getInitialData();
    }, 1000);
  };

  const convertTime = (miliseconds) => {
    const hours = Math.floor(miliseconds / 3600000);
    const minutes = Math.floor((miliseconds % 3600000) / 60000);
    // const seconds = Math.floor((miliseconds % 60000) / 1000);
    return `${hours}h ${minutes}m`;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          marginTop: 73,
          justifyContent: "space-between",
        }}
      >
        <span className={classes.chomebooks}>Chromebooks</span>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            onClick={() => setOpenModalOrgunit(true)}
          >
            <span className={classes.textButton}>Trocar Unidade Organizacional</span>
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            onClick={() => handleSync()}
          >
            <span className={classes.textButton}>Atualizar dados</span>
          </Button>
        </div>
      </div>
      <div className={classes.paper}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <CardItem
              name="Dispositivos provisionados"
              value={totalProvisioned}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <CardItem name="Dispositivos não provisionados" value={totalActive} />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <CardItem
              name="Tempo total de uso"
              value={convertTime(timeUseTotal)}
              isLast
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default CardInfo;

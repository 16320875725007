import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";

const useStyles = makeStyles((theme) => ({
  titleContent: {
    // textAlign: "left",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 55,
    marginBottom: 33,
    background: "#F4F4F4",
    borderRadius: "10px 10px 0px 0px",
    padding: "0 10px",
  },
  secondDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 22,
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#233674",
    whiteSpace: "nowrap",
  },
}));

const TitleContent = ({
  name,
  icon = "AddIcon",
  handleOpen,
  hasOpen = true,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.titleContent}>
      <div>
        <span className={classes.title}>{name}</span>
      </div>
      <div className={classes.secondDiv}>
        {hasOpen && (
          <IconButton onClick={() => handleOpen()} aria-label="delete">
            {icon === "AddIcon" ? <AddIcon /> : <UnfoldMoreIcon />}
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default TitleContent;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Grid } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
// import HeaderBody from "./headerBody";
import CardInfo from "./cardInfo";
import TitleContent from "./titleContent";
import ChromeBookItem from "./chromebookItem";
import SchoolInfo from "./schoolInfo";
import ContentTableModal from "./contentTableModal";
import ContentHankingModal from "./contentHankingModal";

import {
  getChromebooksList,
  getPaginateChromebooks,
  getProvisioned,
  getActive,
  getRankingSchools,
  getPaginateRanking,
  processAll,
} from "../hooks/chrome";
import TableBottom from "./tableBottom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: "0 25px",
  },
  dashContentContainer: {
    marginTop: 20,
  },
  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
  containerInfo: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
    borderRadius: 10,
    minHeight: 550,
    marginBottom: 100,
    paddingBottom: 33,
  },

  containerInfoTableBottom: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
    borderRadius: 10,
    minHeight: 350,
    marginBottom: 100,
    paddingBottom: 33,
    marginTop: -70,
    padding: "0 10px",
  },
}));

function BodyApp({
  // userData,
  mapLimit = 5,
  selectedOrgunitPath,
  setOpenModalOrgunit = () => {},
}) {
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const [openModalHanking, setOpenModalHanking] = useState(false);
  const handleCloseHanking = () => {
    setOpenModalHanking(false);
  };

  const handleOpenHanking = () => {
    handleRankingPaginate();
    setOpenModalHanking(true);
  };

  const [loading, setLoading] = React.useState(false);
  const [chromeBooksList, setChromeBooksList] = useState(null);
  const [chromeBooksListPaginate, setChromeBooksListPaginate] = useState(null);
  const [rankingListPaginate, setRankingListPaginate] = useState(null);

  const [totalProvisioned, setTotalProvisioned] = useState(0);
  const [totalActive, setTotalActive] = useState(0);

  const [rankingSchools, setRankingSchools] = useState(null);

  const getInitialData = async () => {
    setLoading(true);

    processAll();

    setTimeout(async () => {
      const chromeBooks = await getChromebooksList();
      setChromeBooksList(chromeBooks?.chromeosdevices);

      const provisioned = await getProvisioned();
      const active = await getActive();

      setTotalProvisioned(provisioned?.total);
      setTotalActive(active?.total);
      setLoading(false);
      const responseRanking = await getRankingSchools();
      if (responseRanking) {
        setRankingSchools(responseRanking);
      }
    }, 1000);
  };

  const handlePaginate = async (filters) => {
    setLoading(true);
    const { page, perPage, unit, group, period } = filters;
    const chromeBooks = await getPaginateChromebooks(
      page,
      perPage,
      unit,
      group,
      period
    );
    setChromeBooksListPaginate(chromeBooks);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const handleRankingPaginate = async (page = 1, perPage = 5) => {
    const ranking = await getPaginateRanking(page, perPage);
    setRankingListPaginate(ranking);
  };

  useEffect(() => {
    getInitialData();
  }, [selectedOrgunitPath]);

  console.log("chromeBooksList", chromeBooksList);

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <HeaderBody userData={userData} /> */}
      <CardInfo
        totalProvisioned={totalProvisioned}
        totalActive={totalActive}
        timeUseTotal={rankingSchools?.timeUseTotal || 0}
        processAll={processAll}
        getInitialData={getInitialData}
        setLoading={setLoading}
        setOpenModalOrgunit={setOpenModalOrgunit}
      />
      <Grid className={classes.dashContentContainer} container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className={classes.containerInfo}>
            <TitleContent
              name="Relação de dispositivos"
              handleOpen={handleOpen}
            />
            {chromeBooksList &&
              chromeBooksList
                .filter((item) => item.status === "ACTIVE")
                .slice(0, mapLimit)
                .map((item, index) => (
                  <ChromeBookItem item={item} key={index} />
                ))}
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className={classes.containerInfo}>
            <TitleContent
              name="Dispositivos por unidade"
              handleOpen={handleOpenHanking}
            />
            {rankingSchools && <SchoolInfo rankingSchools={rankingSchools} />}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className={classes.containerInfoTableBottom}>
            <TitleContent
              name="Relação de dispositivos por unidade"
              handleOpen={handleOpen}
              hasOpen={false}
            />
            {rankingSchools && (
              <TableBottom
                chromeBooksList={chromeBooksListPaginate}
                handlePaginate={handlePaginate}
                rankingSchools={rankingSchools}
              />
            )}
          </div>
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ContentTableModal
          chromeBooksList={chromeBooksListPaginate}
          handlePaginate={handlePaginate}
          rankingSchools={rankingSchools}
        />
      </Modal>
      <Modal
        open={openModalHanking}
        onClose={handleCloseHanking}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ContentHankingModal
          rankingListPaginate={rankingListPaginate}
          handleRankingPaginate={handleRankingPaginate}
        />
      </Modal>
    </div>
  );
}
export default BodyApp;

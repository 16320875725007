export const DASHBOARD_CHROMEBOOK_EMAIL = "DASHBOARD_CHROMEBOOK_EMAIL";

export function setEmailDashboard(email) {
  return new Promise((resolve) => {
    sessionStorage.setItem(DASHBOARD_CHROMEBOOK_EMAIL, email);

    resolve(true);
  });
}

export function getEmailDashboard() {
  return new Promise((resolve) => {
    const email = sessionStorage.getItem(DASHBOARD_CHROMEBOOK_EMAIL);

    resolve(email);
  });
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Container, makeStyles } from "@material-ui/core";
import BodyApp from "./bodyApp";
import { setEmailDashboard } from "../services/storageEmailService";
import { useOrgunits } from "../hooks/orgunits";
import { ModalSelectOrgUnit } from "./ModalSelectOrgUnit";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    flexGrow: 1,
    paddingBottom: "2.5rem",
    height: "100%",
    overflowX: "hidden",
    backgroundColor: "#F8F8F8",
  },
  formControl: {
    margin: theme.spacing(1),
    marginTop: 5,
    minWidth: 400,
  },
  title: {
    color: "black",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 20,
  },
}));

function Layout({ children, maxWidth = false }) {
  const classes = useStyles();
  const {
    getOrgunits,
    hasSelectedOrgunit,
    saveSelectedOrgunitPath,
    selectedOrgunitPath,
    data: orgUnitsPaths,
    loading: loadingOrgunits,
  } = useOrgunits();

  const [userData, setUserData] = useState(null);

  const handleChangeOrgUnitPath = async (orgUnit) => {
    const response = saveSelectedOrgunitPath({ orgUnitPath: orgUnit });

    if (response) {
      setOpenModalOrgunit(false);
    }
  };

  const [openModalOrgunit, setOpenModalOrgunit] = useState(false);
  const handleCloseOrgunit = () => {
    setOpenModalOrgunit(false);
  };

  window.onmessage = async function (e) {
    const email = e?.data?.email;

    if (!email) {
      alert("Email não informado!");
    } else {
      await setEmailDashboard(email);
      setUserData({ email });
    }
  };

  useEffect(() => {
    if (userData?.email) {
      getOrgunits(userData?.email);
    }
  }, [userData]);

  useEffect(async () => {
    const hasSelected = await hasSelectedOrgunit(userData?.email);
    if (
      !loadingOrgunits &&
      orgUnitsPaths?.children?.length > 0 &&
      !hasSelected
    ) {
      setOpenModalOrgunit(true);
    }
  }, [orgUnitsPaths, loadingOrgunits]);

  return (
    <>
      {userData && (
        <>
          <Container
            className={classes.contentContainer}
            maxWidth={maxWidth}
            {...(maxWidth === false && {
              style: {
                paddingLeft: 0,
                paddingRight: 0,
                margin: 0,
                padding: 0,
              },
            })}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {selectedOrgunitPath && (
                  <BodyApp
                    userData={userData}
                    setOpenModalOrgunit={setOpenModalOrgunit}
                    selectedOrgunitPath={selectedOrgunitPath}
                  />
                )}
                {/* <BodyApp
                  userData={userData}
                  setOpenModalOrgunit={setOpenModalOrgunit}
                  selectedOrgunitPath={selectedOrgunitPath}
                /> */}
              </Grid>
            </Grid>
          </Container>
          <ModalSelectOrgUnit
            openModalOrgunit={openModalOrgunit}
            handleCloseOrgunit={handleCloseOrgunit}
            orgUnitsPaths={orgUnitsPaths}
            selectedOrgunitPath={selectedOrgunitPath}
            loadingOrgunits={loadingOrgunits}
            handleChangeOrgUnitPath={handleChangeOrgUnitPath}
          />
        </>
      )}
    </>
  );
}
export default Layout;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  schoolCard: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    minHeight: 350,
    marginBottom: 15,
    marginLeft: 25,
  },
  schoolItem: {
    height: 50,
    marginBottom: 15,
    width: "100%",
  },
  schoolItemTop: {
    display: "flex",
    alignItems: "center",
  },
  schoolItemName: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 18,
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#484B4D",
    marginLeft: 6,
  },
  small: {
    width: 21,
    height: 21,
    fontSize: 18,
  },
  divPercent: {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  valuePercent: {
    height: 10,
    marginTop: 2,
    borderRadius: 5,
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "#3656BF",
    backgroundColor: "#3656BF",
  },
  valuePercentNumber: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#484B4D",
  },
}));

const SchoolItem = ({ name = "", value = 0, percent = "100%" }) => {
  const classes = useStyles();
  return (
    <div className={classes.schoolItem}>
      <div className={classes.schoolItemTop}>
        <Avatar
          alt={name}
          src="/static/images/avatar/1.jpg"
          className={classes.small}
        />
        <span className={classes.schoolItemName}>{name}</span>
      </div>
      <div className={classes.divPercent}>
        <div
          className={classes.valuePercent}
          style={{ width: `${percent}%` }}
        ></div>
        <div
          className={classes.valuePercentNumber}
          style={{ width: 50, marginLeft: 10, whiteSpace: "nowrap" }}
        >{`${value} (${percent}%)`}</div>
      </div>
    </div>
  );
};

const calculatePercent = (value, total) => {
  const percent = (value * 100) / total;
  return percent.toFixed(0);
};

const formatSchoolName = (name) => {
  if (name === "/") return "Não relacionado";
  return name.replace("/", "");
};

const SchoolInfo = ({ rankingSchools, limit = 5 }) => {
  const classes = useStyles();
  return (
    <div className={classes.schoolCard}>
      {rankingSchools &&
        rankingSchools?.data
          ?.slice(0, limit)
          .map((item, index) => (
            <SchoolItem
              key={index}
              name={formatSchoolName(item?.name)}
              value={item?.value}
              percent={calculatePercent(item.value, rankingSchools?.total)}
            />
          ))}
    </div>
  );
};
export default SchoolInfo;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F5F6FA",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    marginTop: 10,
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    width: "100%",
    maxHeight: "90%",
    overflowY: "auto",
  },
}));

const TableBottom = ({ chromeBooksList, handlePaginate, rankingSchools }) => {
  const classes = useStyles();

  const [page, setPage] = React.useState(1);

  const [unit, setUnit] = React.useState("all");

  const [stateFilter, setStateFilter] = React.useState("all");

  const [statePeriod, setStatePeriod] = React.useState("all");

  useEffect(() => {
    handlePaginate({
      page: page,
      perPage: 10,
      unit,
      group: stateFilter,
      period: statePeriod,
    });
  }, [page, unit, stateFilter, statePeriod]);

  return (
    <Paper className={classes.paper}>
      <CustomizedTables rankingSchools={rankingSchools?.data || []} />
      {/* <div className={classes.root}>
        <Pagination
          count={Math.ceil(chromeBooksList?.total / 10)}
          page={page}
          onChange={handleChangePage}
        />
      </div> */}
    </Paper>
  );
};

function CustomizedTables({ rankingSchools = null }) {
  const classes = useStyles();

  const formatSchoolName = (name) => {
    if (name === "/") return "Não relacionado";
    return name.replace("/", "");
  };

  const convertTime = (miliseconds) => {
    const hours = Math.floor(miliseconds / 3600000);
    const minutes = Math.floor((miliseconds % 3600000) / 60000);
    // const seconds = Math.floor((miliseconds % 60000) / 1000);
    return `${hours}h ${minutes}m`;
  };

  const showRecentUsers = (recentUsers) => {
    return recentUsers.filter(recent => recent?.email).map((recent) => recent.email).join(", ");
  }

  return (
    <TableContainer component={Paper} style={{ maxHeight: "90%" }}>
      <Table
        className={classes.table}
        size="small"
        stickyHeader
        aria-label="sticky table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell>Unidade</StyledTableCell>
            <StyledTableCell align="right">
              Total de dispositivos
            </StyledTableCell>
            <StyledTableCell align="right">
              Dispositivos provisionados
            </StyledTableCell>
            <StyledTableCell align="right">
              Dispositivos não provisionados
            </StyledTableCell>
            <StyledTableCell align="right">Tempo total de uso</StyledTableCell>
            <StyledTableCell align="right">Total de usuários</StyledTableCell>
            <StyledTableCell align="right">Últimos usuários</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rankingSchools &&
            rankingSchools.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {formatSchoolName(row.name)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.totalMachines || ""}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.totalProvisioned || ""}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.totalDeprovisioned || ""}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {convertTime(row.totalUseTime)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.totalRecentsUsers || ""}
                </StyledTableCell>
                <StyledTableCell align="right">
                {row?.recentUsers?.length > 0 && showRecentUsers(row?.recentUsers)}
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default TableBottom;

/* eslint-disable no-unused-vars */
import * as React from "react";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import { makeStyles, Modal, Paper } from "@material-ui/core";
import { useState } from "react";
import { Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    flexGrow: 1,
    paddingBottom: "2.5rem",
    height: "100%",
    overflowX: "hidden",
    backgroundColor: "#cecece",
  },
  formControl: {
    margin: theme.spacing(1),
    marginTop: 5,
    minWidth: 400,
  },
  root: {
    margin: 0,
    height: 350,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    zIndex: 8888,
    position: "absolute",
    maxWidth: "45%",
    top: `50%`,
    left: "50%",
    transform: `translate(-50%, -50%)`,
    "@media (max-width: 600px)": {
      width: "90%",
      top: 25,
      left: "50%",
      transform: `translate(-50%)`,
    },
    border: "2px solid #cecece",
    boxShadow: theme.shadows[5],
    paddingTop: 10,
    paddingBottom: 10,
  },
  title: {
    color: "black",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 18,
    margin: 10,
    textAlign: "center",
  },
  button: {
    margin: theme.spacing(1),
    marginRight: 0,
  },
  textError: {
    fontSize: 13,
    color: "red",
    marginBottom: 20,
  },
}));

export const ModalSelectOrgUnit = ({
  openModalOrgunit,
  loadingOrgunits = false,
  orgUnitsPaths = [],
  selectedOrgunitPath = {},
  handleCloseOrgunit = () => {},
  handleChangeOrgUnitPath = () => {},
}) => {
  const classes = useStyles();

  const [selectedOrgUnit, setSelectedOrgUnit] = useState(null);
  const [orgUnitError, setOrgUnitError] = useState(false);
  const [data, setData] = useState([]);

  function handleSelect(path) {
    setSelectedOrgUnit(path);
  }

  const handleSubmit = () => {
    if (selectedOrgunitPath === selectedOrgUnit) {
      setOrgUnitError(false);
      handleCloseOrgunit();
    } else if (selectedOrgUnit) {
      setOrgUnitError(false);
      handleChangeOrgUnitPath(selectedOrgUnit);
    } else {
      setOrgUnitError(true);
    }
  };

  React.useEffect(() => {
    if (!loadingOrgunits && orgUnitsPaths?.children?.length > 0) {
      setData(orgUnitsPaths);
    }
  }, [orgUnitsPaths, loadingOrgunits]);

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      className={nodes?.id}
      label={nodes.name}
      onClick={(e) => handleSelect(nodes?.orgUnitPath)}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <Modal
      open={openModalOrgunit}
      onClose={() => selectedOrgunitPath && handleCloseOrgunit()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ backgroundColor: "rgba(1,1,1,0.3)" }}
    >
      <Paper className={classes.paper}>
        {loadingOrgunits ? (
          <div>Carregando ...</div>
        ) : (
          <>
            <div className={classes.root}>
              <span className={classes.title}>
                Selecione a pasta em que estão os chromebooks:
              </span>
              <TreeView
                aria-label="rich object"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={["root"]}
                defaultExpandIcon={<ChevronRightIcon />}
                selected={selectedOrgunitPath}
                sx={{
                  maxHeight: 300,
                  flexGrow: 1,
                  width: 400,
                  overflowY: "auto",
                }}
              >
                {!loadingOrgunits &&
                  data?.children?.length > 0 &&
                  renderTree(data)}
              </TreeView>
              {orgUnitError && (
                <span className={classes.textError}>
                  Por favor, selecione a pasta em que estão os chromebooks!
                </span>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: 25,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                onClick={() => handleSubmit()}
              >
                Confirmar
              </Button>
            </div>
          </>
        )}
      </Paper>
    </Modal>
  );
};

/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import api from "../services/api";

const getLogin = (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      const html = await axios.post(`${api.getBaseURL()}/api/v1/login`, {
        email,
      });
      resolve(html.data);
    } catch (error) {
      reject(error);
    }
  });
};

export { getLogin };

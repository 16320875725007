import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import LoginScreen from "./pages/login";
import './index.css';
import { BrowserRouter, Switch, Route } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact={true} component={App} />
        <Route path="/login" component={LoginScreen} />
        {/* <Route path='*' component={ComponenteDePagina404} /> */}
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

import axios from "axios";
import { useState } from "react";
import api from "../services/api";
import { getEmailDashboard } from "../services/storageEmailService";

export const useOrgunits = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedOrgunitPath, setSelectedOrgunitPath] = useState(null);

  const getOrgunits = async (email) => {
    try {
      setLoading(true);
      const email = await getEmailDashboard();
      const response = await axios.get(
        `${api.getBaseURL()}/api/v1/orgunitpath/list?email=${email}`
      );
      setData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const hasSelectedOrgunit = async (email) => {
    return new Promise(async (resolve, reject) => {
      try {
        const email = await getEmailDashboard();
        const response = await axios.get(
          `${api.getBaseURL()}/api/v1/orgunitpath/selected?email=${email}`
        );
        setSelectedOrgunitPath(response?.data?.data?.orgUnitPath);
        resolve(response?.data?.data?.orgUnitPath);
      } catch (error) {
        reject(error);
      }
    });
  };

  const saveSelectedOrgunitPath = async ({ orgUnitPath }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const email = await getEmailDashboard();
        const data = {
          email,
          orgUnitPath: orgUnitPath
        };

        const response = await axios.post(
          `${api.getBaseURL()}/api/v1/orgunitpath/save`,
          data
        );
        setSelectedOrgunitPath(orgUnitPath);
        resolve(response.data.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    getOrgunits,
    hasSelectedOrgunit,
    saveSelectedOrgunitPath,
    selectedOrgunitPath,
    data,
    loading,
  };
};

import { create } from 'apisauce';

const apiUrl = {
  hml: 'http://127.0.0.1:3333',
  prod: 'https://chrome.dash.inicie.dev',
};

const api = create({
  baseURL: process.env.NODE_ENV === 'production' ? apiUrl.prod : apiUrl.hml,
  headers: {
    Authorization: '',
  },
});

export default api;
